// app global css in SCSS form
.date-input {
  width: 200px;
}
.tw-flex {
  flex-wrap: wrap;
}
.backgroundTable {
  background-color: #f5f5f5;
}
.tw-col-span-3.tw-p-2.tw-flex.tw-items-center span {
    display: block;
    width: 100%;
    overflow-wrap: break-word;
}
.q-checkbox__inner--truthy path {
  color: rgb(0, 75, 177);
}
.q-checkbox__inner--truthy .q-checkbox__bg {
  background: white;
}
.q-checkbox__bg {
  border-color: #a1a1aa;
  padding: 5px 5px;
}

.q-checkbox__bg .q-checkbox__svg {
  padding: 3px 3px;
}

.txt-pre-line {
  white-space: pre-line;
}

.bg-img {
  background-color: #3C3C43;
}
.logo-img {
  max-height: 51px;
}
.q-select.selectionbox .q-field__inner .q-field__control .q-field__control-container .q-field__native span {
  white-space: nowrap;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.q-select.selectionbox .q-field__inner .q-field__control .q-field__control-container .q-field__native .q-field__input.q-placeholder {
  max-width: 4px!important;
  width: 4px!important;
  min-width: 4px!important;
}

.sticky-header-column-1-table {
  max-width: 100%;
  max-height: calc(100vh - 130px);
  & tr th {
    position: sticky;
    z-index: 2;
  }
  & thead tr:last-child th {
    z-index: 3;
  }
  & thead tr:first-child th {
    top: 0;
    z-index: 1;
  }
  & tr:first-child th:first-child {
    z-index: 3
  }
  & td:first-child {
    z-index: 1
  }
  & td:first-child,
  & th:first-child {
    position: sticky;
    left: 0;
  }
  & tbody tr:nth-child(odd) {
    background: #ffffff;
  }
}

.sticky-header-column-2-table {
  max-width: 100%;
  max-height: calc(100vh - 130px);
  & tr th {
    position: sticky;
    z-index: 2;
  }
  & thead tr:last-child th {
    z-index: 3;
  }
  & thead tr:first-child th {
    top: 0;
    z-index: 1;
  }
  & tr:first-child th:first-child {
    z-index: 3
  }
  & td:first-child {
    z-index: 1
  }
  & td:nth-child(2),
  & th:nth-child(2) {
    position: sticky;
    left: 0;
  }
  & tbody tr:nth-child(odd) {
    background: #ffffff;
  }
}

.q-skeleton:before {
  content: " ";
}
